import React, { useState } from 'react';

const apiBaseUrl = process.env.REACT_APP_API_URL;

const LoginForm = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Mock API call
        const response = await fetch(`${apiBaseUrl}/api/login/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });
        const data = await response.json();
        if (data.token) {
            onLogin(data.token, username);
        } else {
            alert('Login failed');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="w-100">
            <div className="form-group">
                <label>Username:</label>
                <input
                    type="text"
                    className="form-control"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Password:</label>
                <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            <button type="submit" className="btn btn-primary btn-block">Login</button>
        </form>
    );
};

export default LoginForm;
