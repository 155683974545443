import {
    useMemo,
    useRef,
    type UIEvent,
    useCallback,
} from 'react';
import {useEffect} from 'react';
import {format} from 'date-fns';
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
} from 'material-react-table';
import {Box, Button} from '@mui/material';
import {IconButton} from '@mui/material';
import {Fullscreen, ViewColumn, Search, DensityMedium} from '@mui/icons-material';

import {useState} from 'react';
import {
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_RowVirtualizer,
} from 'material-react-table';

import {Typography} from '@mui/material';
import {Tooltip} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    QueryClient,
    QueryClientProvider,
    keepPreviousData,
    useQuery,
    useInfiniteQuery,
} from '@tanstack/react-query';
import {ClearIcon} from "@mui/x-date-pickers"; //note: this is TanStack React Query V5

const apiBaseUrl = process.env.REACT_APP_API_URL;

type Sms5GCallDetailRecordApiResponse = {
    results: Array<Sms5GCallDetailRecord>;
    count: number;
    meta: {
        totalRowCount: number;
    };
};


type Sms5GCallDetailRecord = {
    cdr_id: number;
    switch_node: number;
    sms_time_in_chosen_timezone: string; // timestamp
    sms_time_out_chosen_timezone: string; // timestamp
    processing_state: number;
    response_code_from_vendor_or_switch: number;
    first_final_dlr_code_from_vendor: string;
    ttd_out: string;
    dlr_received_from_vendor: string; // timestamp
    dlr_cust_delivered: number;
    segm: string | null;
    cycle: number;
    hunt: number;
    last_hunt: number;
    tta_in: string;
    tta_out: string;
    tts: string;
    dst_mccmnc_name: number;
    src_number_in: string;
    src_number_for_routing: string;
    dst_number_in: string;
    dst_number_for_routing: string;
    customer: string;
    vendor: string;
    customer_trunk: string;
    vendor_trunk: string;
    customer_gw: string;
    vendor_gw: string;
    customer_gw_ip: string;
    cust_gw_port: number;
    vendor_gw_ip: string;
    vendor_gw_port: number;
    switch_external_ip_in: string;
    switch_port_in: number;
    switch_external_ip_out: string;
    switch_port_out: number;
    balancer_internal_ip_in: string | null;
    balancer_port_in: string | null;
    balancer_internal_ip_out: string | null;
    balancer_port_out: string | null;
    signaling_internal_ip_in: string | null;
    signaling_port_in: string | null;
    signaling_internal_ip_out: string | null;
    signaling_port_out: string | null;
    sms_received_from_customer: string; // timestamp
    sms_attempt_to_vendor: string; // timestamp
    message_id_internal: number;
    message_id_to_customer: string;
    message_id_from_vendor: string;
    sequence_in: number;
    sequence_out: number;
    sequence_internal_in: string;
    sequence_internal_out: string | null;
    segment_internal_id: string | null;
    customer_code_deck: string;
    message_body_in: string;
};


const Sms5gCallDetailTable = () => {

    const tableContainerRef = useRef<HTMLDivElement>(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer>(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [scrollPosition, setScrollPosition] = useState(0); // Track scroll position
    const [isFetchingMore, setIsFetchingMore] = useState(false); // Added flag for fetch control

    //manage our own state for stuff we want to pass to the API
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
        [],
    );
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });


    // Define columns for the table
    const columns = useMemo<MRT_ColumnDef<Sms5GCallDetailRecord>[]>(
        () => [
            {
                accessorKey: 'cdr_id',
                header: 'CDR ID',
                size: 100,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'switch_node',
                header: 'Switch Node',
                size: 100,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'sms_time_in_chosen_timezone',
                header: 'SMS Time In',
                size: 200,
                Cell: ({cell}) => format(new Date(cell.getValue<string>()), 'yyyy-MM-dd HH:mm:ss'),
                type: 'date',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'sms_time_out_chosen_timezone',
                header: 'SMS Time Out',
                size: 200,
                Cell: ({cell}) => format(new Date(cell.getValue<string>()), 'yyyy-MM-dd HH:mm:ss'),
                type: 'date',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'processing_state',
                header: 'Processing State',
                size: 150,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'response_code_from_vendor_or_switch',
                header: 'Response Code',
                size: 150,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'first_final_dlr_code_from_vendor',
                header: 'First Final DLR Code',
                size: 150,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'ttd_out',
                header: 'TTD Out',
                size: 150,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'dlr_received_from_vendor',
                header: 'DLR Received From Vendor',
                size: 200,
                Cell: ({cell}) => format(new Date(cell.getValue<string>()), 'yyyy-MM-dd HH:mm:ss'),
                type: 'date',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'dlr_cust_delivered',
                header: 'DLR Customer Delivered',
                size: 10,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'segm',
                header: 'Segment',
                size: 100,
                type:'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'cycle',
                header: 'Cycle',
                size: 100,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'hunt',
                header: 'Hunt',
                size: 100,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'last_hunt',
                header: 'Last Hunt',
                size: 100,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'tta_in',
                header: 'TTA In',
                size: 150,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'tta_out',
                header: 'TTA Out',
                size: 150,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'tts',
                header: 'TTS',
                size: 150,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'dst_mccmnc_name',
                header: 'DST MCCMNC Name',
                size: 150,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'src_number_in',
                header: 'Source Number In',
                size: 150,
                type:'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'src_number_for_routing',
                header: 'Source Number For Routing',
                size: 200,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'dst_number_in',
                header: 'Destination Number In',
                size: 150,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'dst_number_for_routing',
                header: 'Destination Number For Routing',
                size: 200,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'customer',
                header: 'Customer',
                size: 150,
                type:'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'vendor',
                header: 'Vendor',
                size: 150,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'customer_trunk',
                header: 'Customer Trunk',
                size: 150,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'vendor_trunk',
                header: 'Vendor Trunk',
                size: 150,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'customer_gw',
                header: 'Customer GW',
                size: 150,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'vendor_gw',
                header: 'Vendor GW',
                size: 150,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'customer_gw_ip',
                header: 'Customer GW IP',
                size: 200,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'cust_gw_port',
                header: 'Customer GW Port',
                size: 150,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'vendor_gw_ip',
                header: 'Vendor GW IP',
                size: 200,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'vendor_gw_port',
                header: 'Vendor GW Port',
                size: 150,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'switch_external_ip_in',
                header: 'Switch External IP In',
                size: 200,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'switch_port_in',
                header: 'Switch Port In',
                size: 150,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'switch_external_ip_out',
                header: 'Switch External IP Out',
                size: 200,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'switch_port_out',
                header: 'Switch Port Out',
                size: 150,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'balancer_internal_ip_in',
                header: 'Balancer Internal IP In',
                size: 200,
                type:'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'balancer_port_in',
                header: 'Balancer Port In',
                size: 150,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'balancer_internal_ip_out',
                header: 'Balancer Internal IP Out',
                size: 200,
                type:'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'balancer_port_out',
                header: 'Balancer Port Out',
                size: 150,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'signaling_internal_ip_in',
                header: 'Signaling Internal IP In',
                size: 200,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'signaling_port_in',
                header: 'Signaling Port In',
                size: 150,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'signaling_internal_ip_out',
                header: 'Signaling Internal IP Out',
                size: 200,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'signaling_port_out',
                header: 'Signaling Port Out',
                size: 150,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'sms_received_from_customer',
                header: 'SMS Received From Customer',
                size: 200,
                Cell: ({cell}) => format(new Date(cell.getValue<string>()), 'yyyy-MM-dd HH:mm:ss'),
                type: 'date',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'sms_attempt_to_vendor',
                header: 'SMS Attempt To Vendor',
                size: 200,
                Cell: ({cell}) => format(new Date(cell.getValue<string>()), 'yyyy-MM-dd HH:mm:ss'),
                type: 'date',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'message_id_internal',
                header: 'Message ID Internal',
                size: 150,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'message_id_to_customer',
                header: 'Message ID To Customer',
                size: 300,
                type:'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'message_id_from_vendor',
                header: 'Message ID From Vendor',
                size: 300,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'sequence_in',
                header: 'Sequence In',
                size: 150,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'sequence_out',
                header: 'Sequence Out',
                size: 150,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'sequence_internal_in',
                header: 'Sequence Internal In',
                size: 150,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'sequence_internal_out',
                header: 'Sequence Internal Out',
                size: 150,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'segment_internal_id',
                header: 'Segment Internal ID',
                size: 150,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'customer_code_deck',
                header: 'Customer Code Deck',
                size: 100,
                type: 'number',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
            {
                accessorKey: 'message_body_in',
                header: 'Message Body',
                size: 300,
                type: 'string',
                enableSorting: true,  // Ensure sorting is enabled
                sortDescFirst: true,
            },
        ],
        []
    );

    //consider storing this code in a custom hook (i.e useFetchUsers)
    const {
        data,
        fetchNextPage,
        fetchPreviousPage,
        isError,
        isFetching,
        isRefetching,
        isLoading,
        refetch,
    } = useInfiniteQuery<Sms5GCallDetailRecordApiResponse>({
        queryKey: [
            'table-data',
            columnFilters, //refetch when columnFilters changes
            globalFilter, //refetch when globalFilter changes
            pagination.pageSize, //refetch when pagination.pageSize changes
            sorting, //refetch when sorting changes
        ],
        queryFn: async () => {
            const fetchURL = new URL(`${apiBaseUrl}/api/sms_5g/`, window.location.origin);

            fetchURL.searchParams.set(
                'page', `${pagination.pageIndex + 1}`,
            );
            fetchURL.searchParams.set('page_size', `${pagination.pageSize}`);

            if (globalFilter) {
                console.log("global filters : ", globalFilter)
                fetchURL.searchParams.set('search', globalFilter);
            }

            const getColumnType = (field) => {
                const column = columns.find(col => col.accessorKey === field);
                return column ? column.type : null; // Return the column type if found, else return null
            };


            if (columnFilters && columnFilters.length > 0) {
                columnFilters.forEach(filter => {

                    let field = filter.id; // e.g., 'plat_writetime', 'sell_zone'
                    let value = filter.value; // e.g., '2024-10-01', 'Peru Mobile'

                    // Get the type of the column based on field
                    const fieldType = getColumnType(field);
                    console.log("field type : ", fieldType)
                    if (fieldType === 'date') {
                        // If the field is a date, apply date-specific logic
                        const formattedDate = format(new Date(value), 'yyyy-MM-dd');
                        console.log("formatted date : ", formattedDate)
                        // Apply range filters for date (if needed)
                        fetchURL.searchParams.set(`${field}__gte`, `${formattedDate}T00:00:00Z`);
                        fetchURL.searchParams.set(`${field}__lte`, `${formattedDate}T23:59:59Z`);

                    } else if (fieldType === 'number') {
                        // If the field is a number, apply exact matching
                        fetchURL.searchParams.set(`${field}__exact`, value);

                    } else if (fieldType === 'string') {
                        // If the field is a string, apply case-insensitive contains
                        fetchURL.searchParams.set(`${field}__icontains`, value);
                    }
                });

                console.log("Applied filters to API request: ", fetchURL.href);
            }


            // Apply sorting
            if (sorting && sorting.length > 0) {
                console.log("sorting filters : ", JSON.stringify(sorting ?? []))
                const ordering = sorting
                    .map(sort => `${sort.desc ? '-' : ''}${sort.id}`)
                    .join(',');

                // Manually append the 'ordering' query param to the URL to prevent automatic URL encoding
                fetchURL.search = `${fetchURL.search}&ordering=${ordering}`;  // Manually append sorting

                console.log("Applied filters to API request: ", fetchURL.href);
            }


            // localStorage.setItem('username', username);
            const token = localStorage.getItem('token');
            console.log("token is : ", token)

            //use whatever fetch library you want, fetch, axios, etc
            const response = await fetch(fetchURL.href, {
                method: 'GET', // or 'POST', 'PUT', etc.
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                    // Add other headers here if needed
                },
            });
            const json = (await response.json()) as Sms5GCallDetailRecordApiResponse;
            return json;
        },
        initialPageParam: 0,
        getNextPageParam: (lastPage, allPages) => {
            // groups.length
            const morePagesExist = allPages.length * pagination.pageSize < lastPage.count;
            return morePagesExist ? allPages.length + 1 : undefined;
        },
        getPreviousPageParam: (firstPage, allPages) => (allPages.length > 1 ? allPages.length - 2 : undefined), // Allow fetching the previous page

        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
    });

    const flatData = useMemo(() => data?.pages.flatMap(page => page.results) ?? [], [data]);

    // Get the total row count from the first page's metadata
    const totalDBRowCount = data?.pages[0]?.count ?? 0;
    const totalFetched = flatData.length;

    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const {scrollHeight, scrollTop, clientHeight} = containerRefElement;
                const scrollDown = scrollTop > scrollPosition; // Check if the user is scrolling down
                setScrollPosition(scrollTop); // Update scroll position

                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                // Handle fetching next page when scrolling down
                if (scrollDown
                    && scrollHeight - scrollTop - clientHeight < 400
                    && !isFetchingMore
                    && !isFetching
                    && totalFetched < totalDBRowCount
                    && pagination.pageIndex < Math.ceil(totalDBRowCount / pagination.pageSize) - 1
                    && pagination.pageIndex >= 0
                ) {
                    setIsFetchingMore(true);
                    fetchNextPage().then(() => {
                        let paginationIndex = totalFetched/pagination.pageSize -1;
                        setPagination(prev => ({
                            ...prev,
                            pageIndex: paginationIndex + 1, // Increment page index
                        }));
                        setIsFetchingMore(false);
                    });
                }
            }
        },
        [fetchNextPage, fetchPreviousPage, isFetchingMore, isFetching, scrollPosition, totalFetched, totalDBRowCount, pagination.pageIndex, pagination.pageSize],
    );

    //scroll to top of table when sorting or filters change
    useEffect(() => {
        //scroll to the top of the table when the sorting changes
        try {
            if( sorting || columnFilters || globalFilter) {
                rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
            }
        } catch (error) {
            console.error(error);
        }
    }, [sorting, columnFilters, globalFilter]);


    const table = useMaterialReactTable({
        columns,
        data: flatData,

        enableRowNumbers: false,
        rowCount: totalDBRowCount,

        enablePagination: true,
        manualPagination: true, //turn off built-in client-side pagination

        enableRowVirtualization: true,

        manualFiltering: true,
        initialState: {showColumnFilters: true},

        manualSorting: true,
        isMultiSortEvent: () => true, //multi-sorting will be the default click behavior without the need to hold shift

        enableColumnOrdering: true,

        muiTableContainerProps: {
            ref: tableContainerRef, //get access to the table container element
            sx: {maxHeight: '500px'}, //give the table a max height
            onScroll: (event: UIEvent<HTMLDivElement>) =>
                fetchMoreOnBottomReached(event.target as HTMLDivElement), //add an event listener to the table container element
        },

        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        renderTopToolbarCustomActions: () => (
            <div>
                <Typography>
                    {flatData.length === 0
                        ? 'No records found'
                        : totalFetched < totalDBRowCount
                            ? `Fetched ${totalFetched} of ${totalDBRowCount} total rows.`
                            : `Fetched ${totalDBRowCount} of ${totalDBRowCount} total rows.`}
                </Typography>
                <Tooltip arrow title="Refresh Data">
                    <IconButton onClick={() => refetch()}>
                        Refresh
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>
                <IconButton onClick={() => table.resetSorting(true)}>
                    Clear All Sorting
                    <ClearIcon fontSize="small"></ClearIcon>
                </IconButton>
            </div>
        ),
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
        rowVirtualizerInstanceRef, //get access to the virtualizer instance
        rowVirtualizerOptions: {overscan: 4},
    });

    return <MaterialReactTable table={table}/>;
};

const queryClient = new QueryClient();

const Sms5gCallDetailTableReactQueryProvider = () => (
    //App.tsx or AppProviders file. Don't just wrap this component with QueryClientProvider! Wrap your whole App!
    <QueryClientProvider client={queryClient}>
        <Sms5gCallDetailTable/>
    </QueryClientProvider>
);

export default Sms5gCallDetailTableReactQueryProvider;
