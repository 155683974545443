import React, {useState} from 'react';
import './AnalyticsComponent.css';
// import DigitalkCallDetailTable from './data_explorer/v1_static_data_table/VoiceDigitalk.tsx';
// import MediacoreSpeedflowCallDetailTable from "./data_explorer/v1_static_data_table/VoiceMediacoreSpeedflow.tsx";
// import Sms5gCallDetailTable from "./data_explorer/v1_static_data_table/Sms5G.tsx";
// import SmsHorisenCallDetailTable from "./data_explorer/v1_static_data_table/SmsHorisen.tsx";

// -----------------

// import DigitalkCallDetailTableReactQueryProvider from './data_explorer/v2_api_data_table/VoiceDigitalk.tsx';
// import SmsHorisenCallDetailTableReactQueryProvider from './data_explorer/v2_api_data_table/SmsHorisen.tsx';
// import MediacoreSpeedflowCallDetailTableReactQueryProvider
//     from './data_explorer/v2_api_data_table/VoiceMediacoreSpeedflow.tsx';
// import Sms5gCallDetailTableReactQueryProvider from './data_explorer/v2_api_data_table/Sms5G.tsx';

// import AnalyticsCountriesVoiceDigitalkTableReactQueryProvider
//     from "./data_explorer/v2_api_data_table/countries/VoiceDigitalk.tsx";
// import AnalyticsCountriesSmsHorisenTableReactQueryProvider
//     from "./data_explorer/v2_api_data_table/countries/SmsHorisen.tsx";
// import AnalyticsCountriesVoiceMediacoreTableReactQueryProvider
//     from "./data_explorer/v2_api_data_table/countries/VoiceMediacore.tsx";
// import AnalyticsCountriesSmsMediacoreTableReactQueryProvider
//     from "./data_explorer/v2_api_data_table/countries/SmsMediacore.tsx";

// -------------------

import DigitalkCallDetailTableReactQueryProvider from './data_explorer/v3_api_data_scroll_globalsearch_filter_sortorder_pagination/cdr_data/Digitalk.tsx';
import SmsHorisenCallDetailTableReactQueryProvider from './data_explorer/v3_api_data_scroll_globalsearch_filter_sortorder_pagination/cdr_data/Horisen.tsx';
import MediacoreSpeedflowCallDetailTableReactQueryProvider
    from './data_explorer/v3_api_data_scroll_globalsearch_filter_sortorder_pagination/cdr_data/MediacoreSpeedflow.tsx';
import Sms5gCallDetailTableReactQueryProvider from './data_explorer/v3_api_data_scroll_globalsearch_filter_sortorder_pagination/cdr_data/Sms5G.tsx';

import AnalyticsCountriesVoiceDigitalkTableReactQueryProvider
    from "./data_explorer/v3_api_data_scroll_globalsearch_filter_sortorder_pagination/countries/VoiceDigitalk.tsx";
import AnalyticsCountriesSmsHorisenTableReactQueryProvider
    from "./data_explorer/v3_api_data_scroll_globalsearch_filter_sortorder_pagination/countries/SmsHorisen.tsx";
import AnalyticsCountriesVoiceMediacoreTableReactQueryProvider
    from "./data_explorer/v3_api_data_scroll_globalsearch_filter_sortorder_pagination/countries/VoiceMediacore.tsx";
import AnalyticsCountriesSmsMediacoreTableReactQueryProvider
    from "./data_explorer/v3_api_data_scroll_globalsearch_filter_sortorder_pagination/countries/SmsMediacore.tsx";

const apiBaseUrl = process.env.REACT_APP_API_URL;

const AnalyticsComponent = () => {
    const [activeComponent, setActiveComponent] = useState('Dashboard');
    const [collapsed, setCollapsed] = useState({dashboard: false, reports: false, settings: false});

    const handleNavigation = (component, event) => {
        event.stopPropagation();
        setActiveComponent(component);
    };

    const toggleCollapse = (section) => {
        setCollapsed(prevState => ({...prevState, [section]: !prevState[section]}));
    };

    return (
        <div className="analytics-container">
            <nav className="navigation-pane">
                <ul>
                    <li onClick={() => toggleCollapse('dataexplorer')}>
                        CDR Data
                        <ul className={`submenu ${collapsed.dataexplorer ? 'collapsed' : ''}`}>
                            <li onClick={(e) => handleNavigation('DataExplorerDigitalk', e)}>Voice - Digitalk</li>
                            <li onClick={(e) => handleNavigation('DataExplorer5G', e)}>SMS - 5G</li>
                            <li onClick={(e) => handleNavigation('DataExplorerHorisen', e)}>SMS - Horisen</li>
                            <li onClick={(e) => handleNavigation('DataExplorerMediacoreSpeedflow', e)}>Voice - Mediacore
                                Speedflow
                            </li>
                        </ul>
                    </li>
                    <li onClick={() => toggleCollapse('analyticscountries')}>
                        Countries
                        <ul className={`submenu ${collapsed.analyticscountries ? 'collapsed' : ''}`}>
                            <li onClick={(e) => handleNavigation('AnalyticsCountriesVoiceDigitalk', e)}>Voice - Digitalk</li>
                            <li onClick={(e) => handleNavigation('AnalyticsCountriesVoiceMediacoreSpeedflow', e)}>Voice - Mediacore
                                Speedflow
                            </li>
                            {/*<li onClick={(e) => handleNavigation('AnalyticsCountries5G', e)}>SMS - 5G</li>*/}
                            <li onClick={(e) => handleNavigation('AnalyticsCountriesSmsMediacoreSpeedflow', e)}>SMS - Mediacore</li>
                            <li onClick={(e) => handleNavigation('AnalyticsCountriesSmsHorisen', e)}>SMS - Horisen</li>

                        </ul>
                    </li>
                    <li onClick={() => toggleCollapse('dashboard')}>
                        Dashboard
                        <ul className={`submenu ${collapsed.dashboard ? 'collapsed' : ''}`}>
                            <li onClick={(e) => handleNavigation('DashboardOverview', e)}>Overview</li>
                            <li onClick={(e) => handleNavigation('DashboardStats', e)}>Stats</li>
                        </ul>
                    </li>
                    <li onClick={() => toggleCollapse('reports')}>
                        Reports
                        <ul className={`submenu ${collapsed.reports ? 'collapsed' : ''}`}>
                            <li onClick={(e) => handleNavigation('ReportsMonthly', e)}>Monthly</li>
                            <li onClick={(e) => handleNavigation('ReportsYearly', e)}>Yearly</li>
                        </ul>
                    </li>
                    <li onClick={() => toggleCollapse('settings')}>
                        Settings
                        <ul className={`submenu ${collapsed.settings ? 'collapsed' : ''}`}>
                            <li onClick={(e) => handleNavigation('SettingsProfile', e)}>Profile</li>
                            <li onClick={(e) => handleNavigation('SettingsAccount', e)}>Account</li>
                        </ul>
                    </li>
                </ul>
            </nav>
            <div className="content-pane">

                {/*{activeComponent === 'DataExplorerDigitalk' &&*/}
                {/*    <div className="content-pane-table"><DigitalkCallDetailTable/></div>}*/}

                {activeComponent === 'DataExplorerDigitalk' &&
                    <div className="content-pane-table"><DigitalkCallDetailTableReactQueryProvider/></div>}

                {/*{activeComponent === 'DataExplorer5G' &&*/}
                {/*    <div className="content-pane-table"><Sms5gCallDetailTable/></div>}*/}

                {activeComponent === 'DataExplorer5G' &&
                    <div className="content-pane-table"><Sms5gCallDetailTableReactQueryProvider/></div>}


                {/*{activeComponent === 'DataExplorerHorisen' &&*/}
                {/*    <div className="content-pane-table"><SmsHorisenCallDetailTable/></div>}*/}

                {activeComponent === 'DataExplorerHorisen' &&
                    <div className="content-pane-table"><SmsHorisenCallDetailTableReactQueryProvider/></div>}

                {/*{activeComponent === 'DataExplorerMediacoreSpeedflow' &&*/}
                {/*    <div className="content-pane-table"><MediacoreSpeedflowCallDetailTable/></div>}*/}

                {activeComponent === 'DataExplorerMediacoreSpeedflow' &&
                    <div className="content-pane-table"><MediacoreSpeedflowCallDetailTableReactQueryProvider/></div>}



                {activeComponent === 'AnalyticsCountriesVoiceDigitalk' &&
                    <div className="content-pane-table"><AnalyticsCountriesVoiceDigitalkTableReactQueryProvider/></div>}

                {/*{activeComponent === 'AnalyticsCountries5G' &&*/}
                {/*    <div className="content-pane-table"><AnalyticsCountries5GTableReactQueryProvider/></div>}*/}

                {activeComponent === 'AnalyticsCountriesSmsHorisen' &&
                    <div className="content-pane-table"><AnalyticsCountriesSmsHorisenTableReactQueryProvider/></div>}

                {activeComponent === 'AnalyticsCountriesVoiceMediacoreSpeedflow' &&
                    <div className="content-pane-table"><AnalyticsCountriesVoiceMediacoreTableReactQueryProvider/></div>}

                {activeComponent === 'AnalyticsCountriesSmsMediacoreSpeedflow' &&
                    <div className="content-pane-table"><AnalyticsCountriesSmsMediacoreTableReactQueryProvider/></div>}



                {activeComponent === 'DashboardOverview' &&
                    <div className="content-pane-table">Dashboard Overview Content</div>}
                {activeComponent === 'DashboardStats' &&
                    <div className="content-pane-table">Dashboard Stats Content</div>}
                {activeComponent === 'ReportsMonthly' &&
                    <div className="content-pane-table">Reports Monthly Content</div>}
                {activeComponent === 'ReportsYearly' &&
                    <div className="content-pane-table">Reports Yearly Content</div>}
                {activeComponent === 'SettingsProfile' &&
                    <div className="content-pane-table">Settings Profile Content</div>}
                {activeComponent === 'SettingsAccount' &&
                    <div className="content-pane-table">Settings Account Content</div>}

            </div>
        </div>
    );
};

export default AnalyticsComponent;
