import React, { useState, useEffect } from 'react';
import LoginForm from './login/LoginForm';
import './App.css';
import AnalyticsComponent from './analytics/AnalyticsComponent';

function App() {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [username, setUsername] = useState(localStorage.getItem('username'));

    const handleLogin = (token, username) => {
        setToken(token);
        setUsername(username);
        localStorage.setItem('token', token);
        localStorage.setItem('username', username);
    };

    const handleLogout = () => {
        setToken(null);
        setUsername('');
        localStorage.removeItem('token');
        localStorage.removeItem('username');
    };

    return (
        <div className="app-container">
            <header className="app-header">
                <h1>BAWAY Analytics</h1>
            </header>
            {token && (
                <div className="welcome-message">
                    Welcome, {username}!
                </div>
            )}
            {token && (
                <button className="btn btn-danger logout-button" onClick={handleLogout}>Logout</button>
            )}
            <div className={`main-content ${token ? 'full-width' : ''}`}>
                {!token ? (
                    <div className="login-container">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="card-title text-center">Login</h3>
                                <LoginForm onLogin={handleLogin} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <AnalyticsComponent />
                )}
            </div>
            <footer className="app-footer">
                <p>&copy; 2023 BAWAY Analytics</p>
            </footer>
        </div>
    );
}

export default App;
